import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Open Source`}</h1>
    <p>{`Eu disponibilizo regularmente alguns repositórios com assuntos que estou estudando atualmente. Eu acredito que a melhor forma de aprender, é construir algo. Nessa página vou listar alguns dos repositórios que eu tenho disponível no meu `}<a parentName="p" {...{
        "href": "https://github.com/robertoachar"
      }}>{`GitHub`}</a>{`.`}</p>
    <h2>{`Monorepo`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/monorepo"
          }}>{`monorepo`}</a><br parentName="p"></br>{`
`}{`A playground for Monorepo with Yarn Workspaces.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/monorepo-eslint-prettier"
          }}>{`monorepo-eslint-prettier`}</a><br parentName="p"></br>{`
`}{`A playground for Monorepo with Yarn Workspaces, ESLint and Prettier.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/monorepo-koa-react"
          }}>{`monorepo-koa-react`}</a><br parentName="p"></br>{`
`}{`A playground for Monorepo with Koa and React.`}</p>
      </li>
    </ul>
    <h2>{`Yeoman Generators`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/generator-oss-project"
          }}>{`generator-oss-project`}</a><br parentName="p"></br>{`
`}{`Yeoman generator for open source projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/generator-node"
          }}>{`generator-node`}</a><br parentName="p"></br>{`
`}{`Yeoman generator for Node projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/generator-react"
          }}>{`generator-react`}</a><br parentName="p"></br>{`
`}{`Yeoman generator for React Apps.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/generator-workshop"
          }}>{`generator-workshop`}</a><br parentName="p"></br>{`
`}{`Yeoman generator for my workshops.`}</p>
      </li>
    </ul>
    <h2>{`Docker`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-node"
          }}>{`docker-node`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Node.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-express"
          }}>{`docker-express`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Express.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-express-mongodb"
          }}>{`docker-express-mongodb`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Express and MongoDB.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-express-gateway"
          }}>{`docker-express-gateway`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Express (Gateway) and MongoDB.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-nginx-express"
          }}>{`docker-nginx-express`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Nginx and Express.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/kubernetes-express"
          }}>{`kubernetes-express`}</a><br parentName="p"></br>{`
`}{`A playground for Kubernetes with Express.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-rabbitmq-node"
          }}>{`docker-rabbitmq-node`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with RabbitMQ and Node.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-react"
          }}>{`docker-react`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with React.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-react-webpack"
          }}>{`docker-react-webpack`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with React and webpack.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-angular"
          }}>{`docker-angular`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Angular.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-mean"
          }}>{`docker-mean`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with MEAN stack (MongoDB, Express, Angular and Node).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-python"
          }}>{`docker-python`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Python.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-python-flask"
          }}>{`docker-python-flask`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Python and Flask.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/docker-go"
          }}>{`docker-go`}</a><br parentName="p"></br>{`
`}{`A playground for Docker with Go.`}</p>
      </li>
    </ul>
    <h2>{`Koa`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa"
          }}>{`playground-koa`}</a><br parentName="p"></br>{`
`}{`A playground for Koa.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-mongodb"
          }}>{`playground-koa-mongodb`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with MongoDB.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-jwt"
          }}>{`playground-koa-jwt`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with JWT.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-auth"
          }}>{`playground-koa-auth`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with authentication and authorization.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-mongodb-rabbitmq"
          }}>{`playground-koa-mongodb-rabbitmq`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with MongoDB and RabbitMQ.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-graphql"
          }}>{`playground-koa-graphql`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with GraphQL.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-koa-graphql-mongodb"
          }}>{`playground-koa-graphql-mongodb`}</a><br parentName="p"></br>{`
`}{`A playground for Koa with GraphQL and MongoDB.`}</p>
      </li>
    </ul>
    <h2>{`Express`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/robertoachar/playground-express-mongodb"
        }}>{`playground-express-mongodb`}</a><br parentName="li"></br>
        {`A playground for Express and MongoDB.`}</li>
    </ul>
    <h2>{`Jest`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-jest-eslint"
          }}>{`playground-jest-eslint`}</a><br parentName="p"></br>{`
`}{`A playground for Jest and ESLint.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/playground-jest-mock"
          }}>{`playground-jest-mock`}</a><br parentName="p"></br>{`
`}{`A playground for Jest with Mock`}</p>
      </li>
    </ul>
    <h2>{`GitHub Package Registry`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/gpr-package"
          }}>{`gpr-package`}</a><br parentName="p"></br>{`
`}{`A playground for GitHub Package Registry with npm.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/gpr-project"
          }}>{`gpr-project`}</a><br parentName="p"></br>{`
`}{`A playground for GitHub Package Registry with npm.`}</p>
      </li>
    </ul>
    <h2>{`RabbitMQ`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/node-rabbitmq"
          }}>{`node-rabbitmq`}</a><br parentName="p"></br>{`
`}{`A playground for Node and RabbitMQ.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/python-rabbitmq"
          }}>{`python-rabbitmq`}</a><br parentName="p"></br>{`
`}{`A playground for Python and RabbitMQ.`}</p>
      </li>
    </ul>
    <h2>{`Fetch + Abort`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/react-fetch-abort-controller"
          }}>{`react-fetch-abort-controller`}</a><br parentName="p"></br>{`
`}{`React + fetch + Abort Controller`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/react-native-fetch-abort-controller"
          }}>{`react-native-fetch-abort-controller`}</a><br parentName="p"></br>{`
`}{`React Native + fetch + Abort Controller`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/robertoachar/react-axios-cancel-token"
          }}>{`react-axios-cancel-token`}</a><br parentName="p"></br>{`
`}{`React + Axios + Cancel Token`}</p>
      </li>
    </ul>
    <h2>{`Lol`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/robertoachar/playground-jquery-redux"
        }}>{`playground-jquery-redux`}</a><br parentName="li"></br>
        {`A playground for jQuery and Redux.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      